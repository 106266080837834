
body
    margin: 0
    font-family: Roboto
    min-width: 320px
    background-color: #ffffff !important
    font-family: Lato, Helvetica, sans-serif
    min-height: 100vh
    overflow-x: hidden

html
    width: 100vw

*, *:after, *:before
    box-sizing: border-box

.PageContent
    display: flex
    flex-direction: column
    margin-right: auto
    margin-left: auto
    padding-top: 20px
    max-width: 700px
    padding-left: 15px
    padding-right: 15px
    border-radius: 4px
    background-color: #fff

    &-no-bg
        background-color: transparent

.Layout
    display: flex
    flex-direction: column
    height: 100%
    width: 100%
    background-color: #fff

.Layout-Base
    flex: 1
    display: flex
    flex-direction: column
    background-color: #ffffff
  
.MuiPickersStaticWrapper-staticWrapperRoot,
.MuiPickersBasePicker-pickerView
    min-width: 292px !important
    max-width: 100% !important